import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';

const NotFoundPage = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  const slides = [
    {
      image: heroImg,
      imageAlt: 'hero image',
    },
  ];

  return (
    <>
      <SEO title="Nie znaleziono" />
      <Header notFound heading="Nie znaleziono<br/>strony" slides={slides} />
    </>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  {
    heroImg: file(name: { eq: "about2" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NotFoundPage;
